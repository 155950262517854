<template>
  <view-item-setting>
    <flex-box>
      <template #default="{ height }">
        <en-table :data="table.data" :height="height" :loading="table.loading">
          <en-table-column label="操作" width="500">
            <template #default="{ row }: { row: EnocloudCommonDefinitions['LookupDto'] }">
              <en-button v-for="item of row.details" type="primary" link @click="table.operation.detail.click(item)">{{ item.message }}</en-button>
            </template>
          </en-table-column>
          <en-table-column label="业务名称" prop="message"></en-table-column>
        </en-table>
      </template>
    </flex-box>
  </view-item-setting>

  <en-drawer v-model="detail.visible" :title="detail.title" size="40%">
    <div class="text-sm text-red-500">鼠标拖动进行排序</div>

    <table class="select-none w-full border-collapse">
      <thead class="bg-gray-200">
        <tr>
          <th class="p-1">字段名称</th>
          <th class="p-1">必填</th>
          <th class="p-1">显示</th>
        </tr>
      </thead>
      <vue-draggable v-model="detail.table.data" tag="tbody" item-key="id">
        <template #item="{ element }: { element: EnocloudCommonDefinitions['FormFieldsSettingDto'] }">
          <tr class="cursor-pointer border-b border-slate-300">
            <td class="p-1 text-center">{{ element.serviceFormFieldsType?.message }}</td>
            <td class="p-1 text-center">
              <en-checkbox
                :model-value="element.required?.value"
                :disabled="!store.accessRightsHash.FORM_SETTING_EDIT || element.serviceFormFieldsType?.forcedRequired"
                @change="detail.table.required.change(element, $event as boolean)"
              ></en-checkbox>
            </td>
            <td class="p-1 text-center">
              <en-checkbox
                :model-value="element.shown?.value"
                :disabled="!store.accessRightsHash.FORM_SETTING_EDIT || element.serviceFormFieldsType?.forcedShow || element.required?.value"
                @change="detail.table.shown.change(element, $event as boolean)"
              ></en-checkbox>
            </td>
          </tr>
        </template>
      </vue-draggable>
    </table>

    <template #footer>
      <en-button @click="detail.footer.cancel.click">取消</en-button>
      <button-ajax :method="detail.footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
import VueDraggable from 'vuedraggable'

export default factory({
  components: { VueDraggable },

  config: {
    children: {
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/common/lookup/:lookupType',
            data: 'array',
            loading: true,
            params(params) {
              params.paths = ['FMBNSTP']
            }
          }
        },
        children: {
          operation: {
            detail: {
              click(item: EnocloudCommonDefinitions['LookupDto']) {
                this.detail.title = item.message
                this.detail.code = item.code
                this.detail.table.get()
                this.detail.visible = true
              }
            }
          }
        }
      },
      detail: {
        visible: false,
        title: '',
        code: '',
        children: {
          table: {
            ajax: {
              get: {
                action: 'GET /enocloud/form/fields/setting',
                data: 'array',
                loading: true,
                params(params) {
                  params.payload = { formFieldsType: this.detail.code }
                }
              },
              submit: {
                action: 'PUT /enocloud/form/fields/setting/:formFieldsTypeCode',
                loading: true,
                params(params) {
                  params.paths = [this.detail.code]
                  params.data = this.detail.table.data.map((item, index) => Object.assign(item, { indexValue: index + 1 }))
                }
              }
            },
            children: {
              required: {
                change(row: EnocloudCommonDefinitions['FormFieldsSettingDto'], value: boolean) {
                  row.required ??= { code: 'N', message: '否', description: '', type: 'FLAG', value: false }
                  row.required.code = value ? 'Y' : 'N'
                  row.required.value = value
                  if (row.serviceFormFieldsType?.code === 'CSMT') {
                    const exist = this.detail.table.data.find((item) => item.serviceFormFieldsType?.code === 'CSLM')
                    if (exist) exist.required = row.required
                  } else if (row.serviceFormFieldsType?.code === 'SSTP') {
                    const exist = this.detail.table.data.find((item) => item.serviceFormFieldsType?.code === 'SSSM')
                    if (exist) exist.required = row.required
                  }
                }
              },
              shown: {
                change(row: EnocloudCommonDefinitions['FormFieldsSettingDto'], value: boolean) {
                  row.shown ??= { code: 'N', message: '否', description: '', type: 'FLAG', value: false }
                  row.shown.code = value ? 'Y' : 'N'
                  row.shown.value = value
                }
              }
            }
          },
          footer: {
            children: {
              cancel: {
                click() {
                  this.detail.visible = false
                }
              },
              confirm: {
                async click() {
                  await this.detail.table.submit()
                  this.detail.visible = false
                }
              }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
